import './App.css';
import Nav from './components/Nav';
import Home from './components/Home';

function App() {
  return (
    <>
      <Nav/>
      <Home/>
    </>
  );
}

export default App;
